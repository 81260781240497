import axios from "axios"
import Store from "../store/index"

const localeInterceptor: any = () =>
  axios.interceptors.request.use(config => {
    const lang: any = Store.state.locale
    config.headers["Accept-Language"] = lang

    return config
  })

export default localeInterceptor
